import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import paper_bg from "../../assets/img/paper_bg.png";
import { v4 as uuidv4 } from 'uuid';
import Header from '../../layout/v3/v3NavigationBar';
import SearchBox from '../v3/searchBox';



function GreetingCard() {

  const navigate = useNavigate();
  const [greetingData, setGreeting] = useState([]);
  const [userId, setUserId] = useState(localStorage.getItem("v3_user_id"));
  const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));
  const [activeCategory, setActiveCategory] = useState(null);
  const path = window.location.pathname;
  const [selectedItems, setSelectedItems] = useState([]);
  const [showWrapper, setShowWrapper] = useState(false);
  const [cartIds, setCartIds] = useState([]);

  const [searchParams] = useSearchParams();
  const EventId = searchParams.get("event_id");
  const Search = searchParams.get("search");
  const Orient = searchParams.get("orient");
  const Cardtype = searchParams.get("cardtype");

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (Search) {
      setIsSearchVisible(true);
      setSearchKeyword(Search);
    } else {
      setIsSearchVisible(false);
    }
  }, [Search]);

  const handleSearchToggle = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname === "/v3/greeting_card" || pathname === "/v3") {
      console.log("Adding classes to the body.");
      document.body.classList.add("bgyellow");

      fetchGreetingData();
    }
    if (!userId) {
      if (!randomId) {
        const uniqueId = uuidv4();
        window.localStorage.setItem("v3_random_id", uniqueId);
      }
    }

  }, []);

  const handlePriceFilterChange = (e) => {
    const selectedRange = e.target.value;
    console.log("selectedRange");//
    console.log(selectedRange); // price=selectedRangeOrient

  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
    console.log("Selected Color:", color);

  };
  const handleorientFilterChange = (e) => {

    const selectedorient = e.target.value;

    const newUrl = location.pathname.includes('?')
      ? `${location.pathname}&orient=${selectedorient}`
      : `${location.pathname}?orient=${selectedorient}`;

    window.history.pushState(null, '', newUrl);
    window.location.reload();
  };
  const handleCardtypeFilterChange = (e) => {
    const selectedCardtype = e.target.value;
    console.log("selectedCardtype");
    console.log(selectedCardtype);

    const newUrl = location.pathname.includes('?')
      ? `${location.pathname}&cardtype=${selectedCardtype}`
      : `${location.pathname}?cardtype=${selectedCardtype}`;

    window.history.pushState(null, '', newUrl);
    window.location.reload();
  };
  const toggleCategory = (index) => {
    setActiveCategory((prev) => (prev === index ? null : index));
  };
  const categoriesData = [
    {
      title: "Birthday",
      subcategories: [
        { name: "Kids", count: 1 },
        { name: "Funny", count: 4, active: true },
        { name: "Milestone", count: 7 },
        { name: "Belated", count: 4 },
        { name: "Add a photo", count: 6 },
        { name: "Family", count: 6 },
        { name: "For her", count: 6 },
        { name: "For him", count: 6 },
      ],
    },
    {
      title: "Events & Occasions",
      subcategories: [
        { name: "New baby", count: 1 },
        { name: "Graduation", count: 4 },
        { name: "Wedding", count: 7 },
        { name: "Anniversary", count: 4 },
        { name: "Retirement", count: 6 },
        { name: "Engagement", count: 6 },
        { name: "Bar Mitzvah", count: 6 },
        { name: "New home", count: 6 },
      ],
    },
    {
      title: "Thank you",
      subcategories: [
        { name: "General", count: 1 },
        { name: "Graduation", count: 4 },
        { name: "Teacher appreciation", count: 7 },
        { name: "For support", count: 4 },
        { name: "Wedding", count: 6 },
        { name: "Birthday", count: 6 },
        { name: "Baby", count: 6 },
        { name: "Baptism", count: 6 },
      ],
    },
    {
      title: "Thoughts & Feelings",
      subcategories: [
        { name: "Get well", count: 1 },
        { name: "Sympathy", count: 4 },
        { name: "Love & Romance", count: 7 },
        { name: "Congratulations", count: 4 },
        { name: "Good luck", count: 6 },
        { name: "Sorry", count: 6 },
        { name: "Miss you", count: 6 },
        { name: "Farewell & goodbye", count: 6 },
      ],
    },
    {
      title: "Holidays",
      subcategories: [
        { name: "Christmas", count: 1 },
        { name: "New year", count: 4 },
        { name: "Hanukkah", count: 7 },
        { name: "Kwanzaa", count: 4 },
        { name: "Chinese new year", count: 6 },
        { name: "Valentine's day", count: 6 },
        { name: "Ramadan", count: 6 },
        { name: "St patrick's day", count: 6 },
      ],
    },
    {
      title: "Announcements",
      subcategories: [
        { name: "Graduation", count: 1 },
        { name: "Memorial", count: 4 },
        { name: "Save the date", count: 7 },
        { name: "Engagement", count: 4 },
        { name: "Wedding", count: 6 },
        { name: "Pregnancy", count: 6 },
        { name: "Birth", count: 6 },
        { name: "Moving", count: 6 },
      ],
    },
  ];

  const fetchGreetingData = async () => {

    try {
      let url = `${process.env.REACT_APP_APIURL}greeting/list`;
      if (Search) {
        url += `?search_text=${encodeURIComponent(Search)}`;
      }
      if (Orient) {
        url += `?orientation=${Orient}`;
      }
      if (Cardtype) {
        url += `?cart_type=${Cardtype}`;
      }
      console.log(url);
      const res = await axios.get(url);
      // const res = await axios.get(`${process.env.REACT_APP_APIURL}greeting/list`);
      if (res.data.status === "success") {

        setGreeting(res.data.result);
      } else {
        toast.error("Failed to fetch greeting data");
      }
    } catch (err) {
      console.error("Error fetching greeting data:", err);
      toast.error("Failed to fetch greeting data");
    }
  };

  const handleAddClick = (id) => {
    if (!selectedItems.includes(id)) {
      setSelectedItems((prev) => [...prev, id]);
      setShowWrapper(true);
    }
  };

  const handleRemoveClick = (id) => {
    setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
    if (selectedItems.length === 1) {
      setShowWrapper(false);
    }
  };

  const handleProceed = async () => {
    try {
      let newCartIds = [];

      const res = await axios.get(`${process.env.REACT_APP_APIURL}event/list?event_id=${EventId}`);
      if (res.data.status === "success") {
        const fetchedCartIds = res.data.result.cart.map((item) => item._id);
        newCartIds = [...newCartIds, ...fetchedCartIds];
        setCartIds((prev) => [...prev, ...fetchedCartIds]);
      } else {
        toast.error("Failed to fetch gift data");
        return;
      }

      for (const item of selectedItems) {
        const addedId = await AddToCart(item);
        if (addedId) {
          newCartIds.push(addedId);
          setCartIds((prev) => [...prev, addedId]);
        }
      }

      const apiUrl = `${process.env.REACT_APP_APIURL}event/update`;
      const payload = {
        event_id: EventId,
        cart_ids: newCartIds.toString(),
      };

      console.log(apiUrl);
      console.log(payload);

      try {
        const response = await axios.post(apiUrl, payload);
        if (response.data.status === "success") {
          console.log("Event updated successfully!");
          window.location.href = `/v3/cart`;
        } else {
        }
      } catch (error) {
        console.error("Update event error:", error);

      }


    } catch (error) {
      toast.error("An error occurred while processing your request");
    }
  };

  const AddToCart = async (id) => {
    const payload = {
      ...(userId ? { user_id: userId } : { random_string: randomId }),
      order_data_count: "1",
      greeting_card: id,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_APIURL}event/add/cart`, payload);
      console.log(`${process.env.REACT_APP_APIURL}event/add/cart`);
      console.log(payload);

      if (response.data.status === "success") {
        return response.data.result._id;
      } else {
        toast.error("Failed to add item to the cart");
        return null;
      }
    } catch (error) {
      toast.error("Failed to add item to the cart");
      return null;
    }
  };

console.log("Orient",Orient);

  return (
    <main>

      <Header onSearchToggle={handleSearchToggle} /> {/* Pass function to Header */}
      <SearchBox isSearchVisible={isSearchVisible} /> {/* Pass state to SearchBox */}

      <section className="card-top pt-130 my-5">
        <div className="container my-4">
          <div className="card invitation-card1 bg_lightgreen">
            <div className="card-body">
              <h1 className="card-title">Birthday Cards</h1>
              <h4>Funny</h4>
              <p className="card-text f_sz16 mt-3">Join us to celebrate a special day filled with fun and joy.</p>
              <img src={paper_bg} />
            </div>
          </div>
        </div>
      </section>
      <section className="tp-shop-area">
        <div className="container">
          <div className="col-md-12">
            <div className="content w-100  mt-50 mb-50">
              <div className="row">
                <div className="col-xl-3 col-lg-4">
                  <div className="tp-shop-sidebar border1 p-3 mb-15">
                    {/* filter */}
                    <div className="tp-shop-widget mt-15 mb-35">
                      <h3 className="tp-shop-widget-title">Card Type</h3>
                      <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-filter">
                          <div id="slider-range" className="mb-10" />
                          <div className="tp-order-info-list-shipping-item d-flex flex-column align-items-start">
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="cardtype1" name="cardtype" value="folded" onChange={handleCardtypeFilterChange} checked={Cardtype === "folded"}/>
                              <label htmlFor="cardtype1">Folded</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="cardtype2" name="cardtype" value="flat" onChange={handleCardtypeFilterChange} checked={Cardtype === "flat"} />
                              <label htmlFor="cardtype2">Flat</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* filter */}
                    <div className="tp-shop-widget mb-35">
                      <h3 className="tp-shop-widget-title">Price</h3>
                      <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-filter">
                          <div id="slider-range" className="mb-10" />
                          <div className="tp-order-info-list-shipping-item d-flex flex-column align-items-start">
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="pricerange1" name="payment" value="any" onChange={handlePriceFilterChange} />
                              <label htmlFor="pricerange1">Any</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="pricerange2" name="payment" value="0to150" onChange={handlePriceFilterChange} />
                              <label htmlFor="pricerange2">Under Rs.150</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="pricerange3" name="payment" value="150to200" onChange={handlePriceFilterChange} />
                              <label htmlFor="pricerange3">Rs.150 to Rs.200</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="pricerange4" name="payment" value="200to250" onChange={handlePriceFilterChange} />
                              <label htmlFor="pricerange4">Rs.200 to Rs.250</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="pricerange5" name="payment" value="250to300" onChange={handlePriceFilterChange} />
                              <label htmlFor="pricerange5">Rs.250 to Rs.300</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* categories */}
                    <div className="tp-shop-widget mb-50">
                      <h3 className="tp-shop-widget-title">Categories</h3>
                      <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-categories">
                          <ul>
                            {categoriesData.map((category, index) => (
                              <li key={index}>
                                <a
                                  className={`category crsr_pntr ${activeCategory === index ? "active" : ""
                                    }`}
                                  onClick={() => toggleCategory(index)}
                                >
                                  {category.title}
                                  <span>
                                    <i
                                      className={`fa-solid ${activeCategory === index
                                        ? "fa-caret-up"
                                        : "fa-caret-down"
                                        }`}
                                    />
                                  </span>
                                </a>
                                <ul
                                  className="ms-3 mt-2 subcategory"
                                  style={{
                                    display: activeCategory === index ? "block" : "none",
                                  }}
                                >
                                  {category.subcategories.map((sub, subIndex) => (
                                    <li key={subIndex}>
                                      <a
                                        href="#"
                                        className={sub.active ? "active" : ""}
                                      >
                                        {sub.name}
                                        <span>{sub.count}</span>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* status */}
                    <div className="tp-shop-widget mb-50">
                      <h3 className="tp-shop-widget-title">Color</h3>

                      <div className="tp-product-details-variation-list">
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#f8b655" ? "active" : ""}`}
                          onClick={() => handleColorClick("#f8b655")}
                        >
                          <span data-bg-color="#f8b655" style={{ backgroundColor: "#f8b655" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#cbcbcb" ? "active" : ""}`}
                          onClick={() => handleColorClick("#cbcbcb")}
                        >
                          <span data-bg-color="#cbcbcb" style={{ backgroundColor: "#cbcbcb" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#494e52" ? "active" : ""}`}
                          onClick={() => handleColorClick("#494e52")}
                        >
                          <span data-bg-color="#494e52" style={{ backgroundColor: "#494e52" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#b4505a" ? "active" : ""}`}
                          onClick={() => handleColorClick("#b4505a")}
                        >
                          <span data-bg-color="#b4505a" style={{ backgroundColor: "#b4505a" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#a850b4" ? "active" : ""}`}
                          onClick={() => handleColorClick("#a850b4")}
                        >
                          <span data-bg-color="#a850b4" style={{ backgroundColor: "#a850b4" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#3441d5" ? "active" : ""}`}
                          onClick={() => handleColorClick("#3441d5")}
                        >
                          <span data-bg-color="#3441d5" style={{ backgroundColor: "#3441d5" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#34d586" ? "active" : ""}`}
                          onClick={() => handleColorClick("#34d586")}
                        >
                          <span data-bg-color="#34d586" style={{ backgroundColor: "#34d586" }} />
                        </button>
                        <button
                          type="button"
                          className={`color tp-color-variation-btn ${selectedColor === "#9e0624" ? "active" : ""}`}
                          onClick={() => handleColorClick("#9e0624")}
                        >
                          <span data-bg-color="#9e0624" style={{ backgroundColor: "#9e0624" }} />
                        </button>
                      </div>
                    </div>
                    {/* categories */}
                    <div className="tp-shop-widget mb-50">
                      <h3 className="tp-shop-widget-title">Orientation</h3>
                      <div className="tp-shop-widget-content">
                        <div className="tp-shop-widget-filter">
                          <div id="slider-range" className="mb-10" />
                          <div className="tp-order-info-list-shipping-item d-flex flex-column align-items-start">
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="orient1" name="orient" value="portrait" onChange={handleorientFilterChange} checked={Orient === "portrait"} />
                              <label htmlFor="orient1">Portrait</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="orient2" name="orient" value="landscape" onChange={handleorientFilterChange} checked={Orient === "landscape"} />
                              <label htmlFor="orient2">Landscape</label>
                            </div>
                            <div className="tp-checkout-payment-item">
                              <input type="radio" id="orient3" name="orient" value="square" onChange={handleorientFilterChange} checked={Orient === "square"} />
                              <label htmlFor="orient3">Square</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="col-xl-9 col-lg-8">
                  <div className="row">
                    {greetingData.map((data) => (
                      <div className="col-xl-4 col-md-6 col-sm-6">
                        <div className="tp-product-item-2 mb-50">
                          <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                            {!EventId ? (
                              <>
                                <a href={`/v3/add_event?id=${data._id}&type=${"greeting_card"}`}>
                                  <img src={data.url} alt={data.name} />
                                </a>
                              </>
                            ) : (
                              <>
                                <a>
                                  <img src={data.url} alt={data.name} />
                                </a>
                              </>
                            )
                            }

                            <div className="tp-product-action">
                              <a href="#"><span className="action_icon"><i className="fa-regular fa-heart f_sz18" /></span></a>
                            </div>
                          </div>
                          <div className="tp-product-content-2 pt-15 position-relative">
                            <h3 className="tp-product-title-2">
                              {data.name}
                            </h3>
                            <div className="tp-product-price-wrapper-2 d-flex align-items-center">
                              <span className="tp-product-price-2 new-price">Rs. {data.price}.00</span>
                              <div className="ms-auto">
                                {!EventId ? (
                                  <a
                                    href={`/v3/add_event?id=${data._id}&type=greeting_card`}
                                    className="btn btn-sm btn-outline-dgreen"
                                  >
                                    Place Order
                                  </a>
                                ) : (
                                  <div>
                                    {selectedItems.includes(data._id) ? (
                                      <a
                                        className="btn btn-sm btn-dgreen removebtn1"
                                        onClick={() => handleRemoveClick(data._id)}
                                      >
                                        Remove
                                      </a>
                                    ) : (
                                      <a
                                        className="btn btn-sm btn-outline-dgreen addbtn addbtn1"
                                        onClick={() => handleAddClick(data._id)}
                                      >
                                        Add
                                      </a>
                                    )}
                                  </div>
                                )}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showWrapper && (
        <div className="deleoptns_wrapper">
          <div className="deleoptns_content position-relative">
            <div className="me-3">
              <a href="#" onClick={() => setShowWrapper(false)}>
                <i className="fa-solid fa-xmark f_20 deleoptns_close" />
              </a>
            </div>
            <div className="ms-2">
              <span className="me-2">{selectedItems.length}</span> selected
            </div>
            <div className="ms-4 me-1">
              <a onClick={() => handleProceed()} className="btn btn-sm btn-yellow">
                Proceed
              </a>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

export default GreetingCard;
