import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Orders() {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname === "/v3/Orders") {
            document.body.classList.add("bgyellow");
        }

        const userId = localStorage.getItem('v3_user_id');
        if (!userId) {
            window.location.href = "/v3";
            setIsLoading(false);
            return;
        }

        const apiUrl = `https://api.eventseasy.in:2020/api/event/list?user_id=${userId}&checkoutlist=true`;

        const fetchEvents = async () => {
            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch events');
                }

                const data = await response.json();

                const filteredEvents = data.result.filter(event =>
                    event.cart_details &&
                    event.cart_details.length > 0 &&
                    event.cart_details.some(cart => cart.gift !== null || cart.greeting_card !== null)
                );

                setEvents(filteredEvents);
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchEvents();
    }, []);

    const eventsum = events.reduce((sum, event) => sum + event.total_price, 0);
    const calculateTotalQuantity = (cartDetails) => {
        return cartDetails.reduce((total, cart) => {
            return total + (cart.order_data_count || 0);
        }, 0);
    };


    return (
        <main>
            {/* login area start */}
            <section className="tp-login-area pt-150 pb-140 p-relative fix">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="position-relative">
                                <div className="row mb-30 align-items-center">
                                    <div className="col-7 col-sm-7 col-lg-7 col-xl-7">
                                        <h3 className="tp-login-title">Your Orders</h3>
                                    </div>
                                </div>
                                <div className="row">

                                {/* Remove the condition      */}
                                    {(eventsum != '0') ? (
                                        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                                            <div className="table-responsive">
                                                <table width="100%" cellPadding={0} cellSpacing={0} className="table table-bordered" style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">S.No</th>
                                                            <th className="text-center">Details</th>
                                                            <th style={{ minWidth: 150 }} className="text-center">Quantity</th>
                                                            <th style={{ minWidth: 150 }} className="text-center">Status</th>
                                                            <th style={{ minWidth: 250 }} className="text-center">Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {events.map((event, index) => (
                                                            <tr>
                                                                <td width={50}>1</td>
                                                                <td width={800}>
                                                                    <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-calendar-days f_darkyellow mr-10" /><span className="fw_pjs700 f_darkgreen f_sz16">{event.event_date}</span></div>
                                                                    <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-user f_darkyellow mr-10" /><span className="fw_pjs700">{event.event_name}</span></div>
                                                                    <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-calendar-check f_darkyellow mr-10" />{event.event_type}</div>
                                                                    <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-phone f_darkyellow mr-10" />{event.phone_number}</div>
                                                                    <div className="txtdsply f_sz14 mb-10"><i className="fa-solid fa-map-location-dot f_darkyellow mr-10" />
                                                                        {event.address}
                                                                    </div>
                                                                    <div className="brdr_btm w-100 mt-10 mb-10" />
                                                                    <div className="flexdiv">
                                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                                                            <div className="fw_pjs700">Greeting Card</div>
                                                                            <div className="txtdsply f_sz14 mb-10"><i className="fa-solid fa-file-pen f_darkyellow mr-10" />
                                                                                {event.greeting_notes}
                                                                            </div>
                                                                            {event.cart_details.map((item, index) => (
                                                                                item.greeting_card && (
                                                                                    (item.greeting_card.url != null) ? (
                                                                                        <div className="greetingcardgift_wrapper">
                                                                                            <div className="greetingcardgift_item">
                                                                                                <div className="greetingcardgift_img_sm">
                                                                                                    <img
                                                                                                        src={item.greeting_card.url} alt={item.greeting_card.name}
                                                                                                        border={0}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="greetingcardgift_price">
                                                                                                    Rs. {item.greeting_card.price}.00
                                                                                                </div>
                                                                                                <div className="greetingcardgift_qty">
                                                                                                    <span className="qtysection w-100 justify-content-center mt-1"><span className="f_sz16 fw_pjs600 me-2">Quantity:</span> {item.order_data_count}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : ''

                                                                                )
                                                                            ))}
                                                                        </div>
                                                                        {event.cart_details.map((item, index) => (
                                                                            item.gift && (
                                                                                (item.gift.url != null) ? (
                                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                                                                        <div className="fw_pjs700">Gift</div>
                                                                                        <div className="greetingcardgift_wrapper">
                                                                                            <div className="greetingcardgift_item">
                                                                                                <div className="greetingcardgift_img_sm">
                                                                                                    <img
                                                                                                        src={item.gift.url} alt={item.gift.name}
                                                                                                        border={0}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="greetingcardgift_price">
                                                                                                    Rs. {item.gift.price}.00
                                                                                                </div>
                                                                                                <div className="greetingcardgift_qty">
                                                                                                    <span className="qtysection w-100 justify-content-center mt-1"><span className="f_sz16 fw_pjs600 me-2">Quantity:</span> {item.order_data_count}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : ''
                                                                            )
                                                                        ))}
                                                                    </div>
                                                                </td>
                                                                <td className="text-center f_sz16">{calculateTotalQuantity(event.cart_details)}</td>
                                                                <td className="text-center">
                                                                    <div className="order_stat order_delivered">Delivered</div>
                                                                    <div className="mt-10"><span className="fw_pjs700">Delivered:</span><br /> 19 Jan 2025</div>
                                                                </td>
                                                                <td className="txtalgn_rgt fw_pjs700 f_sz16">
                                                                    Rs. {event.total_price}.00
                                                                </td>
                                                            </tr>

                                                        ))}
                                                    </tbody><tfoot>
                                                        <tr>
                                                            <td colSpan={4} className="txtalgn_rgt fw_pjs700">
                                                                <div className="f_sz18">Subtotal</div>
                                                                <div className="f_sz18 mt-10">Shipping</div>
                                                                <div className="f_sz18 mt-10">Tax</div>
                                                                <div className="f_sz22 mt-10">Total for sending greeting card and gift </div>
                                                            </td>
                                                            <td className="txtalgn_rgt fw_pjs700">
                                                                <div className="f_sz18">Rs. {events.reduce((acc, curr) => acc + curr.total_price, 0).toFixed(2)}</div>
                                                                <div className="f_sz18 mt-10">Rs. 0</div>
                                                                <div className="f_sz18 mt-10">Rs. 0</div>
                                                                <div className="f_sz22 mt-10"> Rs. {events.reduce((acc, curr) => acc + curr.total_price, 0).toFixed(2)} </div>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    ) : (<div className="row mb-30 align-items-center">
                                        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                                            <div className="w-100 d-flex flex-row justify-content-center flex-wrap mt-25 f_sz16">
                                                There are no orders.
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>

    );
}

export default Orders;
