import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import ProductsList from './components/list';
import Home from './components/home';
import Header from './layout/header';
import Footer from './layout/footer';
import Signup from './components/signup';
import Signin from './components/login';
import ForgotPassword from './components/forgot_password';
import ResetPassword from './components/reset_password';
import RequestList from './components/request_list';
import Request from './components/request';
import HeaderV3 from './layout/v3/v3header';
import FooterV3 from './layout/v3/v3footer';
import Homev3 from './components/v3/homev3';
import SigninV3 from './components/v3/login';
import SignupV3 from './components/v3/signup';
import ForgotPasswordV3 from './components/v3/forgot_password';
import ResetPasswordV3 from './components/v3/reset_password';
import Event from './components/v3/event';
import EventEdit from './components/v3/event_edit';
import ViewEvent from './components/v3/view_event';
import Checkout from './components/v3/checkout';
import GreetingCard from './components/v3/greeting_card';
import Gift from './components/v3/gift';
import AddEvent from './components/v3/add_event';
import Cart from './components/v3/cart';
import Profile from './components/v3/profile';
import Orders from './components/v3/orders';
import Confirmation from './components/v3/confirmation';
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedInv3, setIsLoggedInV3] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    const isAuthenticated = checkUserAuthentication();
    setIsLoggedIn(isAuthenticated);
  }, []);

  const checkUserAuthentication = () => {
    const token = window.localStorage.getItem('user_id');
    return !!token; 
  };

  useEffect(() => {
    const isAuthenticated = checkUserAuthenticationv3();
    setIsLoggedInV3(isAuthenticated);
  }, []);

  const checkUserAuthenticationv3 = () => {
    const token = window.localStorage.getItem('v3_user_id');
    return !!token; 
  };

  const isV3Header = location.pathname.startsWith('/v3');

  return (
    <>
    <div className="content">
      {isV3Header ? <HeaderV3 /> : <Header />}
      <Routes>
        {/* <Route path="/" element={<ProductsList />} /> */}
        <Route path="/" element={<ProductsList />} />
        <Route path="/v2" element={<Home />} />
        <Route path="/login" element={!isLoggedIn ? <Signin setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/requestlist" />} />
        <Route path="/register" element={!isLoggedIn ? <Signup setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/requestlist" />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/requestlist" element={isLoggedIn ? <RequestList /> : <Navigate to="/login" />} />
        <Route path="/request" element={<Request />} />

        {/* V3 Start */ }
        {/* <Route path="/v3" element={<Homev3 />} /> */}
        <Route path="/v3" element={<GreetingCard />} />
        <Route path="/v3/" element={<GreetingCard />} />
        <Route path="/v3/login" element={!isLoggedInv3 ? <SigninV3 setIsLoggedInV3={setIsLoggedInV3} /> : <Navigate to="/v3" />} />
        <Route path="/v3/register" element={!isLoggedInv3 ? <SignupV3 setIsLoggedInV3={setIsLoggedInV3} /> : <Navigate to="/v3" />} />
        <Route path="/v3/forgot_password" element={<ForgotPasswordV3 />} />
        <Route path="/v3/reset-password" element={<ResetPasswordV3 />} />
        <Route path="/v3/event" element={<Event />} />
        {/* <Route path="/v3/add_event" element={<EventEdit />} />
        <Route path="/v3/edit_event" element={<EventEdit />} /> */}
        <Route path="/v3/view_event" element={<ViewEvent />} />
        <Route path="/v3/checkout" element={<Checkout />} />
        <Route path="/v3/greeting_card" element={<GreetingCard />} />
        <Route path="/v3/gift" element={<Gift />} />
        <Route path="/v3/add_event" element={<AddEvent />} />
        <Route path="/v3/edit_event" element={<EventEdit />} />
        <Route path="/v3/cart" element={<Cart />} />
        <Route path="/v3/profile" element={<Profile />} />
        <Route path="/v3/orders" element={<Orders />} />
        <Route path="/v3/confirmation" element={<Confirmation />} />
      </Routes>
      
    </div>
    {isV3Header ? <FooterV3 /> : <Footer />}
    </>
  );
};

export default App;
