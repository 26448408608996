import { io } from 'socket.io-client';

const SOCKET_SERVER_URL = 'https://api.eventseasy.in:2020/';

let socket;

export const initializeSocket = () => {
  if (!socket) {
    socket = io(SOCKET_SERVER_URL, {
      transports: ['websocket'], // Ensure WebSocket transport is used
    });

    socket.on('connect', () => {
      console.log('Connected to the server!');
    });

    socket.on('connect_error', (err) => {
      console.error('Connection error:', err);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from the server');
    });
  }
  return socket;
};

export const getSocket = () => {
  if (!socket) {
    console.warn('Socket not initialized. Call initializeSocket() first.');
  }
  return socket;
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
    console.log('Socket disconnected');
  }
};