import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const shadowimg = `${process.env.PUBLIC_URL}../assets/img/shadowimg.png`;
// const shadowimg = `${process.env.PUBLIC_URL}../../`;
function Checkout() {
  const [searchParams] = useSearchParams();
  const [eventlist, setEventList] = useState(null); // Updated to null 
  const [configinfo, setConfigInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("v3_user_id");
  const EventId = searchParams.get("id");
  const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));
  const [showAnimation, setShowAnimation] = useState(false);
  const [greetingCards, setGreetingCards] = useState([]);
  const [gifts, setGifts] = useState([]);

  const [deleteId, setDeleteId] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [cartId, setCartId] = useState(null);


  useEffect(() => {
    if (eventlist && eventlist.cart) {
      const fetchItems = async () => {
        const greetingPromises = eventlist.cart
          .filter(item => item.greeting_card)
          .map(item =>
            axios
              .get(`${process.env.REACT_APP_APIURL}greeting/list?greeting_id=${item.greeting_card}`)
              .then(res => ({
                ...res.data.result,
                cartId: item._id,
                order_cound: item.order_data_count
              }))
          );

        const giftPromises = eventlist.cart
          .filter(item => item.gift)
          .map(item =>
            axios
              .get(`${process.env.REACT_APP_APIURL}gift/list?gift_id=${item.gift}`)
              .then(res => ({
                ...res.data.result,
                cartId: item._id,
                order_cound: item.order_data_count
              }))
          );

        const greetings = await Promise.all(greetingPromises);
        const giftsData = await Promise.all(giftPromises);

        setGreetingCards(greetings.flat());
        setGifts(giftsData.flat());
      };

      fetchItems();
    }
  }, [eventlist]);

  useEffect(() => {
    const pathname = location.pathname.startsWith("/v3");
    if (pathname) {
      document.body.classList.add("bgyellow");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    fetchconfiginfo();
    const key = userId ? "user_id" : "random_string";
    const value = userId || randomId;

    const url = !EventId
      ? `${process.env.REACT_APP_APIURL}event/list?${key}=${value}&checkoutlist=true`
      : `${process.env.REACT_APP_APIURL}event/list?checkoutlist=true&event_id=${EventId}`;

    axios
      .get(url)
      .then((res) => {
        if (res.data.status === "success") {
          setEventList(res.data.result);
        } else {
          toast.error("Failed to fetch event data.");
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
        toast.error("Error fetching events.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchconfiginfo = () => {

    const url = `${process.env.REACT_APP_APIURL}config/info`


    axios
      .get(url)
      .then((res) => {
        if (res.data.status === "success") {
          setConfigInfo(res.data.result);
        } else {
          toast.error("Failed to fetch event data.");
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
        toast.error("Error fetching events.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const calculateTotalQuantity = (cartDetails = []) => {
    return cartDetails.reduce((total, cart) => {
      return total + (cart.order_data_count || 0);
    }, 0);
  };
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const [day, month, year] = dateString.split("-");
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];
    return `${day} ${monthNames[parseInt(month, 10) - 1]} ${year}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!eventlist) {
    return <div>No events available for checkout.</div>;
  }
  const greetingData = async (id, type) => {
    const res = await axios.get(`${process.env.REACT_APP_APIURL}${type}/list?${type}_id"=${id}`);
    if (res.data.status === "success") {
      return res.data.result;
    }

  }

  const handlepay = () => {
    console.log("test");
    if (userId) {

      setShowAnimation(true);
      setTimeout(() => {
        window.location.href = `/v3/confirmation?id=${EventId}`;
      }, 3000);

      //  return;
    } else {
      window.localStorage.setItem("v3_signup_eventId", EventId);
      window.location.href = `/v3/login?id=${EventId}`;
    }
  }

  const fullAddress = [
    eventlist?.address,
    eventlist?.city,
    eventlist?.state,
    eventlist?.zip_code,
  ].filter(Boolean).join(", ");



  const calculateTotalAmount = (cart, greetingCards, gifts) => {
    let total = 0;

    cart.forEach((item) => {
      const quantity = item.order_data_count;
      if (item.greeting_card) {
        const card = greetingCards.find((gc) => gc._id === item.greeting_card);
        if (card) total += parseInt(card.price * quantity || 0) ;
      }
      if (item.gift) {
        const gift = gifts.find((g) => g._id === item.gift);
        if (gift) total += parseInt(gift.price * quantity || 0);
      }
    });

    return total;
  };
  const handleDeleteEvent = async (id) => {

    const key = userId ? "user_id" : "random_string";
    const value = userId || randomId;

    const payload = {
      [key]: value,
      event_id: id
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}event/delete`,
        payload
      );

      if (response.data.status === "success") {
        window.location.href = `/v3/cart`;
      } else {

      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Failed to add to cart");
    }

  };
  const eventsum = calculateTotalAmount(eventlist.cart, greetingCards, gifts);

  const shippingCost = 60;
  const tax = "5";
  const taxAmount = (eventsum * (tax / 100)).toFixed(2);

  const totalAmount = (shippingCost + eventsum + parseFloat(taxAmount)).toFixed(2);

  const handlesingleproductDelete = async (cartId, eventId) => {

    let newCartIds = [];

    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}event/list?event_id=${eventId}`);
      if (res.data.status === "success") {
        const fetchedCartIds = res.data.result.cart.map((item) => item._id);
        newCartIds = fetchedCartIds.filter((id) => id !== cartId);
      } else {
        toast.error("Failed to fetch gift data");
        return;
      }
    } catch (error) {
      toast.error("An error occurred while fetching gift data");
    }


    const apiUrl = `${process.env.REACT_APP_APIURL}event/update`;
    const payload = {
      event_id: eventId,
      cart_ids: newCartIds.toString(),
    };


    try {
      const response = await axios.post(apiUrl, payload);
      if (response.data.status === "success") {
        console.log("item deleted successfully!");
        fetchData();
      } else {
      }
    } catch (error) {
      console.error("Update event error:", error);
    }
  };
  const renderGreetingCards = () =>
    greetingCards.length > 0 ? (
      greetingCards.map((card, index) => (
        <div key={index} className="greetingcardgift_wrapper">
          <div className="greetingcardgift_item">
            <div className="greetingcardgift_img_sm position-relative">
              <img src={card.url} alt={card.name} />
              <div className="delete_icon_sm">
                <i data-bs-toggle="modal" data-bs-target="#modal_removeproductfromcart" onClick={() => {
                  setCartId(card.cartId);
                  setEventId(eventlist._id);
                }} className="fa-regular fa-trash-can f_sz18 f_red" />
              </div>
            </div>
            <div className="greetingcardgift_price">
              <span>{card.name}</span>
              <span className="f_sz14 w-100"> Rs. {card.price}.00</span>
            </div>
            <div className="flexdiv justify-content-center align-items-center mt-5">
              <h6 className="me-3">Quantity</h6>
              <div className="tp-product-quantity mt-10 mb-10">
                <span className="tp-cart-minus">
                  <i className="fa-solid fa-minus crsr_pntr" />
                </span>
                <input className="tp-cart-input" type="text" defaultValue={card.order_cound} readOnly />
                <span className="tp-cart-plus">
                  <i className="fa-solid fa-plus crsr_pntr" />
                </span>
              </div>
            </div>

          </div>
        </div>
      ))
    ) : (
      // <div>No greeting cards added.</div>
      ""
    );
  const renderGifts = () =>
    gifts.length > 0 ? (
      gifts.map((gift, index) => (
        <div key={index} className="greetingcardgift_wrapper">
          <div className="greetingcardgift_item">
            <div className="greetingcardgift_img_sm position-relative">
              <img src={gift.url} alt={gift.name} />
              <div className="delete_icon_sm">
                <i
                  data-bs-toggle="modal" data-bs-target="#modal_removeproductfromcart" onClick={() => {
                    setCartId(gift.cartId);
                    setEventId(eventlist._id);
                  }}
                  className="fa-regular fa-trash-can f_sz18 f_red" />
              </div>
            </div>
            <div className="greetingcardgift_price">
              <span>{gift.name}</span>
              <span className="f_sz14 w-100"> Rs. {gift.price}.00</span>
            </div>
            <div className="flexdiv justify-content-center align-items-center mt-5">
              <h6 className="me-3">Quantity</h6>
              <div className="tp-product-quantity mt-10 mb-10">
                <span className="tp-cart-minus">
                  <i className="fa-solid fa-minus crsr_pntr" />
                </span>
                <input className="tp-cart-input" type="text" defaultValue={gift.order_cound} readOnly />
                <span className="tp-cart-plus">
                  <i className="fa-solid fa-plus crsr_pntr" />
                </span>
              </div>
            </div>

          </div>
        </div>
      ))
    ) : (
      // <div>No gifts added.</div>
      ""
    );


  return (
    <>
      <main>
        <section className="tp-login-area pt-150 pb-140 p-relative fix">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <div className="position-relative">
                  <div className="row mb-30 align-items-center">
                    <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                      <h3 className="tp-login-title">Checkout</h3>
                    </div>
                  </div>

                  {(EventId) ? (
                    <div className="row">
                      <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="table-responsive">
                          <table width="100%" cellPadding={0} cellSpacing={0} className="table table-bordered" style={{ width: '100%' }}>
                            <thead>
                              <tr>
                                <th style={{ minWidth: 50 }} className="text-center">S.No</th>
                                <th style={{ minWidth: 600 }} className="text-center">Details</th>
                                <th style={{ minWidth: 250 }} className="text-center">Quantity</th>
                                <th style={{ minWidth: 250 }} className="text-center">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>
                                  <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-calendar-days f_darkyellow mr-10" /><span className="fw_pjs700 f_darkgreen f_sz16">{formatDate(eventlist.event_date)}</span></div>
                                  <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-user f_darkyellow mr-10" /><span className="fw_pjs700">{eventlist.event_name}</span></div>
                                  <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-calendar-check f_darkyellow mr-10" />{eventlist.event_type}</div>
                                  <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-phone f_darkyellow mr-10" />{eventlist.phone_number}</div>
                                  <div className="txtdsply f_sz14 mb-10"><i className="fa-solid fa-map-location-dot f_darkyellow mr-10" />
                                    {fullAddress}
                                  </div>
                                  <div className="brdr_btm w-100 mt-10 mb-10" />
                                  <div className="flexdiv">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                      <div className="fw_pjs700">Greeting Card</div>
                                      <div className="txtdsply f_sz14 mb-10"><i className="fa-solid fa-file-pen f_darkyellow mr-10" />
                                        {eventlist.greeting_notes}
                                      </div>
                                      {renderGreetingCards()}
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                      <div className="fw_pjs700">Gift</div>
                                      {renderGifts()}
                                    </div>
                                  </div>
                                  <div className="brdr_btm w-100 mt-10 mb-10" />
                                  <div className="w-100 d-flex flex-row flex-wrap">
                                    <div><a href={`/v3/greeting_card?event_id=${eventlist._id}`} className="btn btn-yellow btn-sm m-2">Add Another Greeting Card</a>
                                    </div>
                                    <div>
                                      <a href={`/v3/gift?event_id=${eventlist._id}`} className="btn btn-yellow btn-sm m-2">Add Another Gift</a>
                                    </div>
                                    <div><a data-bs-toggle="modal" data-bs-target="#modal_removefromcart" onClick={() => setDeleteId(eventlist._id)} className="btn btn-danger btn-sm m-2">Delete</a></div>
                                  </div>
                                </td>
                                <td className="text-center f_sz16">{greetingCards.length + gifts.length}</td>
                                <td className="text-center fw_pjs700 f_sz16">
                                  Rs. {eventsum}.00
                                </td>
                              </tr>
                            </tbody><tfoot>
                              <tr>
                                <td colSpan={3} className="txtalgn_rgt fw_pjs700">
                                  <div className="f_sz18">Subtotal</div>
                                  <div className="f_sz18 mt-10">Shipping</div>
                                  <div className="f_sz18 mt-10">Tax</div>
                                  <div className="f_sz22 mt-10">Total for sending greeting card and gift </div>
                                </td>
                                <td className="text-center fw_pjs700">
                                  {/* <div className="f_sz18">Rs. {greetingCards.reduce((sum, card) => sum + parseInt(card.price || 0), 0)}.00</div>
                                  <div className="f_sz18 mt-10">Rs. {gifts.reduce((sum, gift) => sum + parseInt(gift.price || 0), 0)}.00</div> */}
                                  <div className="f_sz18 mt-10">Rs. {eventsum}.00</div>
                                  <div className="f_sz18 mt-10">Rs. {shippingCost}.00</div>
                                  <div className="f_sz18 mt-10">Rs. {taxAmount}</div>
                                  <div className="f_sz18 mt-10">Rs. {totalAmount}</div>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                      <div className="w-100 d-flex flex-row flex-wrap">
                        <span className="fw_pjs700">Note: </span>You cannot make changes to your order once order is placed and paid.
                      </div>
                      <div className="w-100 d-flex flex-row flex-wrap text-center justify-content-center mt-55">
                        <div><a onClick={(e) => {
                          e.preventDefault();
                          handlepay();
                        }} className="btn btn-dgreen btnpdng_md m-2">Pay</a></div>
                      </div>
                    </div>
                  ) : (
                    <div className="row mb-30 align-items-center">
                      <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="w-100 d-flex flex-row justify-content-center flex-wrap mt-25 f_sz16">
                          No checkouts in your list.
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {showAnimation && (
              <div className="animation-overlay">
                <div className="logo-container">
                  <img
                    src={shadowimg} // Replace with your logo file path or URL
                    alt="Logo"
                    className="loadinglogo"
                  />
                </div>
              </div>
            )}
          </div>
        </section>
        {/* login area end */}

        <div className="modal fade" id="modal_removefromcart" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
              <div className="modal-body">
                <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                  <div className="greetingcardgift_wrapper justify-content-center">
                  </div>
                  Are you sure you want to remove the event.
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <a onClick={() => handleDeleteEvent(deleteId)} data-bs-dismiss="modal" className="btn btn-yellow">Yes, remove</a>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal_removeproductfromcart" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
              <div className="modal-body">
                <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                  <div className="greetingcardgift_wrapper justify-content-center">
                  </div>
                  Are you sure you want to remove the product from event.
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <a data-bs-dismiss="modal" onClick={() => handlesingleproductDelete(cartId, eventId)} className="btn btn-yellow">Yes, remove</a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  );
}

export default Checkout;

