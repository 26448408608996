import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function Cart() {
  const [userId, setUserId] = useState(localStorage.getItem("v3_user_id"));
  const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));

  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [display, setDisplay] = useState(false);
  const [configinfo, setConfigInfo] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [cartId, setCartId] = useState(null);


  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === "/v3/cart") {
      document.body.classList.add("bgyellow");
    }
    if (!userId) {
      // window.location.href = "/v3/login";
    }

    fetchCartList();

  }, []);

  const fetchCartList = async () => {
    setLoading(true);
    fetchconfiginfo();
    const key = userId ? "user_id" : "random_string";
    const value = userId || randomId;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}event/list?${key}=${value}&checkoutlist=true`
      );

      if (res.data.status === "success") {
        setCartData(res.data.result);
        if (res.data.result.length > 0) {
          setDisplay(true);
        }

      } else {
        if (res.data.status != "no_record") {
          toast.error("Failed to fetch greeting data");
        }
        setCartData([]);
      }
    } catch (err) {
      console.error("Error fetching cart data:", err);
      toast.error("Failed to fetch cart data");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckout = (id, action) => {
    if (action == "checkout") {
      window.location.href = `/v3/checkout?id=${id}`;
    }

  }

  const fetchconfiginfo = () => {

    const url = `${process.env.REACT_APP_APIURL}config/info`


    axios
      .get(url)
      .then((res) => {
        if (res.data.status === "success") {
          setConfigInfo(res.data.result);
        } else {
          toast.error("Failed to fetch event data.");
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
        toast.error("Error fetching events.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteEvent = async (id) => {

    const key = userId ? "user_id" : "random_string";
    const value = userId || randomId;

    const payload = {
      [key]: value,
      event_id: id
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}event/delete`,
        payload
      );

      if (response.data.status === "success") {
        fetchCartList();
      } else {

      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Failed to add to cart");
    }

  };

  const handlesingleproductDelete = async (cartId, eventId) => {
    let newCartIds = [];

    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}event/list?event_id=${eventId}`);
      if (res.data.status === "success") {
        const fetchedCartIds = res.data.result.cart.map((item) => item._id);
        newCartIds = fetchedCartIds.filter((id) => id !== cartId);
      } else {
        toast.error("Failed to fetch gift data");
        return;
      }
    } catch (error) {
      toast.error("An error occurred while fetching gift data");
    }


    const apiUrl = `${process.env.REACT_APP_APIURL}event/update`;
    const payload = {
      event_id: eventId,
      cart_ids: newCartIds.toString(),
    };

    console.log(apiUrl);
    console.log(payload);

    try {
      const response = await axios.post(apiUrl, payload);
      if (response.data.status === "success") {
        console.log("item deleted successfully!");
        fetchCartList();
      } else {
      }
    } catch (error) {
      console.error("Update event error:", error);

    }
  };

  const handlQuantityClick = (giftId, count, itemType, incrementType, cartid) => {

    const newQuantity = incrementType === "increment" ? count + 1 : count - 1;

    if (newQuantity < 1) {
      toast.error("Minimum quantity is 1");
      return;
    }

    setCartData((prevCartData) =>
      prevCartData.map((item) =>
        item.cart_details.map((cartItem) => {
          if (cartItem._id === cartid) {
            return { ...cartItem, order_data_count: newQuantity };
          }
          return cartItem;
        })
      )
    );

    console.log(giftId, newQuantity, itemType, cartid);
    AddToCart(giftId, newQuantity, itemType, cartid);
  };

  const AddToCart = async (id, quantity, type, cartid) => {

    const payload = {
      ...(userId ? { user_id: userId } : { random_string: randomId }),
      order_data_count: quantity,
      cart_id: cartid,
      ...(type === "greeting_card"
        ? { greeting_card: id }
        : { gift: id })
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}event/add/cart`,
        payload
      );
      if (response.data.status === "success") {
        toast.success("Added to cart successfully!", { autoClose: 1000 });
        fetchCartList();

      } else {
        toast.error("Failed to add to cart");
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Failed to add to cart");
    }
  };

  if (loading) {
    return <div>Loading your cart...</div>;
  }
  const totalSum = cartData.reduce((sum, item) => {
    const hasGiftOrCard = item.cart_details?.some(
      (cartItem) => cartItem.gift || cartItem.greeting_card
    );

    return hasGiftOrCard ? sum + (item.total_price || 0) : sum;
  }, 0);

  const shippingCost = 60;
  const tax = "5";
  const taxAmount = (totalSum * (tax / 100)).toFixed(2);

  const totalAmount = (shippingCost + totalSum + parseFloat(taxAmount)).toFixed(2);

  return (
    <div>
      <div className="content">
        <main>
          <section className="tp-login-area pt-150 pb-140 p-relative fix">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="position-relative">
                    <div className="row mb-30 align-items-center">
                      <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                        <h3 className="tp-login-title">Cart</h3>
                      </div>
                    </div>
                    {display ? (
                      <div className="row">

                        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                          <div className="table-responsive">

                            <table width="100%" cellPadding={0} cellSpacing={0} className="table table-bordered" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th style={{ minWidth: 50 }} className="text-center">S.No</th>
                                  <th style={{ minWidth: 600 }} className="text-center">Details</th>
                                  <th style={{ minWidth: 250 }} className="text-center">Quantity</th>
                                  <th style={{ minWidth: 250 }} className="text-center">Price</th>
                                </tr>
                              </thead>
                              <tbody>

                                {cartData.map((item, index) => {
                                  const hasGiftOrCard = item.cart_details?.some(
                                    (cartItem) => cartItem.gift || cartItem.greeting_card
                                  );

                                  return hasGiftOrCard ? (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <div className="txtdsply f_sz14 mb-5">
                                          <i className="fa-solid fa-calendar-days f_darkyellow mr-10" />
                                          <span className="fw_pjs700 f_darkgreen f_sz16">{item.event_date}</span>
                                        </div>
                                        <div className="txtdsply f_sz14 mb-5">
                                          <i className="fa-solid fa-user f_darkyellow mr-10" />
                                          <span className="fw_pjs700">{item.event_name}</span>
                                        </div>
                                        <div className="txtdsply f_sz14 mb-5">
                                          <i className="fa-solid fa-calendar-check f_darkyellow mr-10" />
                                          {item.event_type}
                                        </div>
                                        <div className="txtdsply f_sz14 mb-5">
                                          <i className="fa-solid fa-phone f_darkyellow mr-10" />
                                          +91{item.phone_number}
                                        </div>
                                        <div className="txtdsply f_sz14 mb-10">
                                          <i className="fa-solid fa-map-location-dot f_darkyellow mr-10" />
                                          {item.address}
                                        </div>
                                        <div className="brdr_btm w-100 mt-10 mb-10" />
                                        <div className="flexdiv" >
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="fw_pjs700">Greeting Card</div>
                                            <div className="txtdsply f_sz14 mb-10"><i className="fa-solid fa-file-pen f_darkyellow mr-10" />
                                              {item.greeting_notes}
                                            </div>
                                            {item.cart_details.filter(cartItem => cartItem.greeting_card).map((cartItem, cartIndex) => (
                                              <>

                                                <div className="greetingcardgift_wrapper">
                                                  <div className="greetingcardgift_item">
                                                    <div className="greetingcardgift_img_sm position-relative">
                                                      <img
                                                        src={cartItem.greeting_card.url}
                                                        alt={cartItem.greeting_card.name}
                                                        border={0}
                                                      />
                                                      <div className="delete_icon_sm">
                                                        <i data-bs-toggle="modal" data-bs-target="#modal_removeproductfromcart" onClick={() => {
                                                          setCartId(cartItem._id);
                                                          setEventId(item._id);
                                                        }} className="fa-regular fa-trash-can f_sz18 f_red" />
                                                      </div>
                                                    </div>
                                                    <div className="greetingcardgift_price">
                                                      <span>{cartItem.greeting_card.name}</span>
                                                      <span className="f_sz14 w-100">
                                                        Rs. {cartItem.greeting_card.price}.00
                                                      </span>
                                                    </div>
                                                    <div className="flexdiv justify-content-center align-items-center mt-5">
                                                      <h6 className="me-3">Quantity</h6>
                                                      <div className="tp-product-quantity mt-10 mb-10">
                                                        <span className="tp-cart-minus">
                                                          <i onClick={() => handlQuantityClick(cartItem.greeting_card.greeting_card_id, cartItem.order_data_count, "greeting_card", "decrement", cartItem._id)} className="fa-solid fa-minus crsr_pntr" />
                                                        </span>
                                                        <input className="tp-cart-input" type="text" defaultValue={cartItem.order_data_count} readOnly/>
                                                        <span className="tp-cart-plus">
                                                          <i onClick={() => handlQuantityClick(cartItem.greeting_card.greeting_card_id, cartItem.order_data_count, "greeting_card", "increment", cartItem._id)} className="fa-solid fa-plus crsr_pntr" />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                            {item.cart_details.filter(cartItem => cartItem.gift).map((cartItem, cartIndex) => (
                                              <>
                                                <div className="fw_pjs700">Gift</div>
                                                <div className="greetingcardgift_wrapper">
                                                  <div className="greetingcardgift_item">
                                                    <div className="greetingcardgift_img_sm position-relative">
                                                      <img
                                                        src={cartItem.gift.url}
                                                        alt={cartItem.gift.name}
                                                        border={0}
                                                      />
                                                      <div className="delete_icon_sm">
                                                        <i data-bs-toggle="modal" data-bs-target="#modal_removeproductfromcart" onClick={() => {
                                                          setCartId(cartItem._id);
                                                          setEventId(item._id);
                                                        }} className="fa-regular fa-trash-can f_sz18 f_red" />
                                                      </div>
                                                    </div>
                                                    <div className="greetingcardgift_price">
                                                      <span>{cartItem.gift.name}</span>
                                                      <span className="f_sz14 w-100">
                                                        Rs. {cartItem.gift.price}.00
                                                      </span>
                                                    </div>
                                                    <div className="flexdiv justify-content-center align-items-center mt-5">
                                                      <h6 className="me-3">Quantity</h6>
                                                      <div className="tp-product-quantity mt-10 mb-10">
                                                        <span className="tp-cart-minus">
                                                          <i onClick={() => handlQuantityClick(cartItem.gift.gift_id, cartItem.order_data_count, "gift", "decrement", cartItem._id)} className="fa-solid fa-minus crsr_pntr" />
                                                        </span>
                                                        <input className="tp-cart-input" type="text" defaultValue={cartItem.order_data_count} readOnly />
                                                        <span className="tp-cart-plus">
                                                          <i onClick={() => handlQuantityClick(cartItem.gift.gift_id, cartItem.order_data_count, "gift", "increment", cartItem._id)} className="fa-solid fa-plus crsr_pntr" />
                                                        </span>
                                                      </div>
                                                    </div>

                                                  </div>

                                                </div>
                                              </>
                                            ))}
                                          </div>
                                        </div>

                                        <div className="brdr_btm w-100 mt-10 mb-10" />
                                        <div className="w-100 d-flex flex-row flex-wrap">
                                          <div>
                                            <a
                                              href={`/v3/greeting_card?event_id=${item._id}`}
                                              className="btn btn-yellow btn-sm m-2"
                                            >
                                              Add Another Greeting Card
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              href={`/v3/gift?event_id=${item._id}`}
                                              className="btn btn-yellow btn-sm m-2"
                                            >
                                              Add Another Gift
                                            </a>
                                          </div>
                                          <div>
                                            <a
                                              onClick={() => {
                                                handleCheckout(item._id, "checkout");
                                              }}
                                              className="btn btn-dgreen btn-sm m-2"
                                            >
                                              Proceed To Checkout
                                            </a>
                                          </div>
                                          <div>
                                            <a data-bs-toggle="modal" data-bs-target="#modal_removefromcart" onClick={() => setDeleteId(item._id)} className="btn btn-danger btn-sm m-2">Delete</a>
                                          </div>

                                        </div>
                                      </td>
                                      <td className="text-center f_sz16">{item.cart_details.length}</td>
                                      <td className="text-center fw_pjs700 f_sz16">Rs.{item.total_price}.00</td>
                                    </tr>
                                  ) : null; // Skip rendering if no gift or greeting_card
                                })}



                              </tbody><tfoot>
                                <tr>
                                  <td colSpan={3} className="txtalgn_rgt fw_pjs700">
                                    <div className="f_sz18">Subtotal</div>
                                    <div className="f_sz18 mt-10">Shipping</div>
                                    <div className="f_sz18 mt-10">Tax</div>
                                    <div className="f_sz22 mt-10">Total for sending greeting card and gift </div>
                                  </td>
                                  <td className="text-center fw_pjs700">
                                    <div className="f_sz18">Rs. {totalSum}.00</div>
                                    <div className="f_sz18 mt-10">Rs. {shippingCost}.00</div>
                                    <div className="f_sz18 mt-10">Rs. {taxAmount}</div>
                                    <div className="f_sz22 mt-10">Rs. {totalAmount}</div>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>



                        </div>
                        <div className="w-100 d-flex flex-row flex-wrap text-center justify-content-center mt-55">
                          <div><a href="#" className="btn btn-dgreen btnpdng_md m-2">Proceed To Checkout</a></div>
                        </div>
                      </div>
                    ) : (<div className="row mb-30 align-items-center">
                      <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="w-100 d-flex flex-row justify-content-center flex-wrap mt-25 f_sz16">
                          No carts added in your list.
                        </div>
                      </div>
                    </div>)}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* login area end */}
          <div className="modal fade" id="modal_removefromcart" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
                <div className="modal-body">
                  <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                    <div className="greetingcardgift_wrapper justify-content-center">
                    </div>
                    Are you sure you want to remove the event.
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <a onClick={() => handleDeleteEvent(deleteId)} data-bs-dismiss="modal" className="btn btn-yellow">Yes, remove</a>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modal_removeproductfromcart" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
                <div className="modal-body">
                  <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                    <div className="greetingcardgift_wrapper justify-content-center">
                    </div>
                    Are you sure you want to remove the product from event.
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <a data-bs-dismiss="modal" onClick={() => handlesingleproductDelete(cartId, eventId)} className="btn btn-yellow">Yes, remove</a>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>

  );
}

export default Cart;