import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ViewEvent() {
  const [searchParams] = useSearchParams();
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("v3_user_id");
  const EventId = searchParams.get("id");

  useEffect(() => {
    const pathname = location.pathname.startsWith("/v3");
    if (pathname) {
      document.body.classList.add("bgyellow");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios 
      .get(`${process.env.REACT_APP_APIURL}event/list?event_id=${EventId}`)
      .then((res) => {
        if (res.data.status === "success") {
          setEventList(res.data.result);
        } else {
          // toast.error("Failed to fetch events.");
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
        // toast.error("Error fetching events.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${day} ${monthNames[parseInt(month, 10) - 1]} ${year}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <main>
        <section className="tp-login-area pt-120 pb-140 p-relative fix">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="tp-login-wrapper position-relative">
                  <div className="row mb-30 align-items-center">
                    <div className="col-7">
                      <h3 className="tp-login-title">View Event</h3>
                    </div>
                    <div className="col-5 text-end">
                      <a
                        href="/v3/event"
                        className="f_sz14 f_darkyellow txt_underline"
                      >
                        Back
                      </a>
                    </div>
                  </div>
                  <div className="w-100">
                    {/* Event Details */}
                    <div className="event_dtl">
                      <div className="event_dtldate">
                        {formatDate(eventList.event_date)}
                      </div>
                    </div>
                    <div className="flexdiv mt-25">
                      <h6 className="f_sz20 w-100">Recipient Details</h6>
                    </div>
                    <div className="row mt-15 mb-10">
                      <div className="col-6">
                        <div className="mb-10">
                          <div className="txtdsply f_sz18 mb-10">
                            <i className="fa-solid fa-user f_darkyellow mr-10" />
                            {eventList.event_name}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-10">
                          <div className="txtdsply f_sz18 mb-10">
                            <i className="fa-solid fa-calendar-check f_darkyellow mr-10" />
                            {eventList.event_type}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-20">
                      <div className="col-12">
                        <div className="mb-10">
                          <div className="txtdsply f_sz18 mb-10">
                            <i className="fa-solid fa-map-location-dot f_darkyellow mr-10" />
                            {eventList.address}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="brdr_btm w-100 mt-35 mb-35" />
                    {/* Greeting Card Section */}
                    <div className="flexdiv mb-3">
                      <h6 className="f_sz20 w-100 mb-10">Greeting Card</h6>
                      <div className="mb-10">
                        <div className="txtdsply f_sz18 mb-10">
                          <i className="fa-solid fa-file-pen f_darkyellow mr-10" />
                          {eventList.greeting_notes}
                        </div>
                      </div>
                      {eventList.greeting_card?
                      <div className="flexdiv">
                        <h6 className="mb-15">
                          <span
                            className="f_sz14 f_darkyellow crsr_pntr"
                            data-bs-toggle="modal"
                            data-bs-target="#modalgreetingcard_view"
                          >
                            (Click here to view full size)
                          </span>
                        </h6>
                        <div className="greetingcardgift_wrapper">
                        {eventList.greeting_card.map((data) => (
                        <div className="greetingcardgift_item" key={data._id}>
                          <div className="greetingcardgift_img">
                            <img src={data.url} alt={data.name} />
                          </div>
                          <div className="greetingcardgift_price">
                            Rs. {data.price}.00
                          </div>
                        </div>
                      ))}
                        </div>
                      </div>:""}
                    </div>
                  </div>
                  <div className="brdr_btm w-100 mt-35 mb-35" />
                  {/* Gift Section */}
                  <div className="flexdiv mb-3">
                    <h6 className="f_sz20 w-100 mb-10">
                      Gift{" "}
                      <span
                        className="f_sz14 f_darkyellow crsr_pntr"
                        data-bs-toggle="modal"
                        data-bs-target="#modalgift_view"
                      >
                        (Click here to view full size)
                      </span>
                    </h6>
                    <div className="greetingcardgift_wrapper">
                      {eventList.gift.map((data) => (
                        <div className="greetingcardgift_item" key={data._id}>
                          <div className="greetingcardgift_img">
                            <img src={data.url} alt={data.name} />
                          </div>
                          <div className="greetingcardgift_price">
                            Rs. {data.price}.00
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="w-100 d-flex flex-row flex-wrap text-center justify-content-center mt-55">
                    <div>
                      <a href={`/v3/edit_event?id=${eventList._id}`} className="btn btn-yellow m-2">
                        Edit Event
                      </a>
                    </div>
                    <div>
                      <a href="/v3/checkout" className="btn btn-yellow m-2">
                        Proceed To Checkout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
      {/*Greeting Card Modal*/}
      {eventList.greeting_card?<div
        className="modal fade"
        id="modalgreetingcard_view"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalgreetingcard_viewLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>
            <div className="modal-header">
              <h5 className="modal-title" id="modalgreetingcard_viewLabel">
                Greeting Card
              </h5>
            </div>
            <div className="modal-body">
              <div className="greetingcard_full">
                <div
                  id="carouselgreetingcardIndicators1"
                  className="carousel slide"
                  data-bs-ride="carousel"
                  data-bs-pause="false"
                >
                  <div className="carousel-inner">
                    {/* {eventList.map((data, metaindex) => ( */}
                    <div
                      key={0}
                      className={`carousel-item ${0 === 0 ? "active" : ""}`}
                    >
                      <img src={eventList.greeting_card.url} border={0} />
                    </div>
                    {/* ))} */}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselgreetingcardIndicators1"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-left" />
                    </span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselgreetingcardIndicators1"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-right" />
                    </span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>:""}
      {/*Gift Modal*/}
      <div
        className="modal fade"
        id="modalgift_view"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalgift_viewLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>
            <div className="modal-header">
              <h5 className="modal-title" id="modalgift_viewLabel">
                Gift
              </h5>
            </div>
            <div className="modal-body">
              <div className="greetingcard_full">
                <div
                  id="carouselgiftIndicators1"
                  className="carousel slide"
                  data-bs-ride="carousel"
                  data-bs-pause="false"
                >
                  <div className="carousel-inner">
                    {eventList.gift.map((data, metaindex) => (
                      <div
                        key={metaindex}
                        className={`carousel-item ${
                          metaindex === 0 ? "active" : ""
                        }`}
                      >
                        <img src={data.url} border={0} />
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselgiftIndicators1"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-left" />
                    </span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselgiftIndicators1"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    >
                      <i className="fa-solid fa-chevron-right" />
                    </span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewEvent;
