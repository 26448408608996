import React, { useState, useEffect } from "react";
import logo from "../../assets/img/logo.png";
import axios from "axios";
import { initializeSocket, getSocket, disconnectSocket } from "../../components/v3/socket";
const NavigationBar3 = ({ onSearchToggle }) => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cartCount, setCartCount] = useState();
  const [userId, setUserId] = useState(localStorage.getItem("v3_user_id"));
  const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));

  useEffect(() => {
    const isAuthenticated = checkUserAuthentication();
    setIsLoggedIn(isAuthenticated);
  }, [isLoggedIn]);

  useEffect(() => {
    const socket = initializeSocket();

    if (socket) {
      socket.on('addtoevent', (data) => {
        if (data.user_id == userId || data.random_string == randomId) {
          fetchCartList();
        }
      });
    }

    return () => {
      disconnectSocket();
    };
  }, []);

  const checkUserAuthentication = () => {
    const token = window.localStorage.getItem("v3_user_id");
    return !!token;
  };

  useEffect(() => {
    const email_address = window.localStorage.getItem("v3_email_address");
    setEmail(email_address);
    const first_name = window.localStorage.getItem("v3_first_name");
    setFirstname(first_name);
    fetchCartList();
  }, []);


  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/v3";
  };

  const fetchCartList = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}event/list?${userId ? `user_id=${userId}` : `random_string=${randomId}&checkoutlist=true`
        }`);
      if (res.data.status === "success") {
        setCartCount(res.data.result.length);
      }
    } catch (err) {
      console.error("Error fetching cart data:", err);
    }
  };

  const path = window.location.pathname;

  return (
    <>
      <nav className="navbar navbar-expand-xl fixed-top topheader_nav">
        <div className="container-fluid justify-content-end">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="flexdiv">
                <div className="location_header me-2" style={{ display: 'none' }}>

                  <a data-bs-toggle="modal" data-bs-target="#modallocation" className="crsr_pntr"><i className="fa-solid fa-location-dot f_yellow me-1" /> Select Delivery Location <i className="fa-solid fa-angle-down ms-1" /></a>
                </div>
                {!isLoggedIn ? (
                  <>
                    <div className="topheader_link mx-2"><a href="/v3/register">Sign Up</a></div>
                    <div className="topheader_link mx-2"><a href="/v3/login">Login</a></div>
                  </>
                ) : (
                  ''
                )}
                <div className="topheader_link me-2">
                  <a href="/v3/cart">
                    <span className="position-relative">
                      <i className="fa-solid fa-cart-shopping f_yellow f_sz18 posrel_top3"></i>{cartCount > 0 ? <span class="cart_count">{cartCount}</span> : ""}
                    </span>
                  </a>
                </div>
                {isLoggedIn ? (
                  <>
                    <div className="topheader_link nav-link dropdown navprofile">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="prof_img">{firstname ? firstname.charAt(0).toUpperCase() : " "}</span>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li className="brdr_btm pb-10 px-2">
                          <span className="f_darkyellow f_sz14">
                            {firstname}
                          </span>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/v3/Profile">
                            My Profile
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/v3/Checkout">
                            Checkout
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/v3/Orders">
                            Your Orders
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" onClick={handleLogout} href="#">
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <nav className="navbar navbar-expand-xl fixed-top bottomheader_nav">
        <div className="container-fluid">
          <a className="navbar-brand" href="/v3">
            <img src={logo} border={0} className="desktop_logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"><i className="fa-solid fa-bars f_30" /></span>
          </button>
          {/* {(path === "/v3/greeting_card" || path === "/v3/gift" || path === "/v3") ? ( */}

            <div className="navsearch_product_icon" onClick={onSearchToggle}>
              <i className="fa-solid fa-magnifying-glass" />
            </div>
          

          <div className="collapse navbar-collapse" id="navbarToggler">
            <ul className="navbar-nav ms-auto mt-10 mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a className={`nav-link dropdown-toggle ${path === "/v3/greeting_card" ? "active" : ""}`} href="/v3/greeting_card" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Greeting Cards
                </a>

                <div className="dropdown-menu dropdown-menu-lg megamenu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <ul>
                    <li><a className="dropdown-item">Birthday</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card?getval=Kids">Kids</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Funny</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Milestone</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Belated</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Add a photo</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Family</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">For her</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">For him</a></li>
                  </ul>
                  <ul>
                    <li><a className="dropdown-item">Events &amp; Occasions</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">New baby</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Graduation</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Wedding</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Anniversary</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Retirement</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Engagement</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Bar Mitzvah</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">New home</a></li>
                  </ul>
                  <ul>
                    <li><a className="dropdown-item">Thank you</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">General</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Graduation</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Teacher appreciation</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">For support</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Wedding</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Birthday</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Baby</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Baptism</a></li>
                  </ul>
                  <ul>
                    <li><a className="dropdown-item">Thoughts &amp; Feelings</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Get well</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Sympathy</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Love &amp; Romance</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Congratulations</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Good luck</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Sorry</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Miss you</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Farewell &amp; goodbye</a></li>
                  </ul>
                  <ul>
                    <li><a className="dropdown-item">Holidays</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Christmas</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">New year</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Hanukkah</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Kwanzaa</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Chinese new year</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Valentine`s day</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Ramadan</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">St patrick`s day</a></li>
                  </ul>
                  <ul>
                    <li><a className="dropdown-item">Announcements</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Graduation</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Memorial</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Save the date</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Engagement</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Wedding</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Pregnancy</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Birth</a></li>
                    <li><a className="dropdown-item" href="/v3/greeting_card">Moving</a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a className={`nav-link dropdown-toggle ${path === "/v3/gift" ? "active" : ""}`} href="/v3/gift" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Gifts
                </a>
                <div className="dropdown-menu dropdown-menu-lg megamenu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <ul>
                    <li><a className="dropdown-item">Birthday</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Kids</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Funny</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Milestone</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Belated</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Add a photo</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Family</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">For her</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">For him</a></li>
                  </ul>
                  <ul>
                    <li><a className="dropdown-item">Events &amp; Occasions</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">New baby</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Graduation</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Wedding</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Anniversary</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Retirement</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Engagement</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Bar Mitzvah</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">New home</a></li>
                  </ul>
                  <ul>
                    <li><a className="dropdown-item">Thank you</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">General</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Graduation</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Teacher appreciation</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">For support</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Wedding</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Birthday</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Baby</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Baptism</a></li>
                  </ul>
                  <ul>
                    <li><a className="dropdown-item">Thoughts &amp; Feelings</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Get well</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Sympathy</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Love &amp; Romance</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Congratulations</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Good luck</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Sorry</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Miss you</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Farewell &amp; goodbye</a></li>
                  </ul>
                  <ul>
                    <li><a className="dropdown-item">Holidays</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Christmas</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">New year</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Hanukkah</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Kwanzaa</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Chinese new year</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Valentine`s day</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Ramadan</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">St patrick`s day</a></li>
                  </ul>
                  <ul>
                    <li><a className="dropdown-item">Announcements</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Graduation</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Memorial</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Save the date</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Engagement</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Wedding</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Pregnancy</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Birth</a></li>
                    <li><a className="dropdown-item" href="/v3/gift">Moving</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
 
    </>
  );
};

export default NavigationBar3;
